<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global styles */
:root {
  --primary-color: #4a90e2;
  --secondary-color: #2c3e50;
  --background-color: #f5f7fa;
  --text-color: #333;
  --border-radius: 8px;
  --transition-speed: 0.3s;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

#app {
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Responsive container */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Common utility classes */
.card {
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: transform var(--transition-speed);
}

.card:hover {
  transform: translateY(-2px);
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: all var(--transition-speed);
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity var(--transition-speed);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
